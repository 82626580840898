// app/javascript/controllers/redirect_controller.js
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["dateRange"]

  connect() {
    flatpickr(this.dateRangeTarget, {
      mode: "range",
      onChange: this.loadData.bind(this)
    });
    $('#load-data-btn')[0].click()
  }

  loadData() {
    let startDate = this.dateRangeTarget.value.split(" to ")[0]
    let endDate = this.dateRangeTarget.value.split(" to ")[1]

    if (startDate && endDate) {
      let url = '/admin/dashboard/data'
      let formData = new FormData()
      formData.append('start_date', startDate)
      formData.append('end_date', endDate)

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': this.getMetaValue('csrf-token')
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
