// app/javascript/controllers/calculator_controller.js

import { Controller } from "@hotwired/stimulus"
import { param } from "jquery";

export default class extends Controller {
  static targets = ["serviceFees", "planAmount", "numberOfInstallments", "paydayCycle", "paydayAmount", "totalPlanAmount", "numberOfMonths",
    "planNumber", "treatmentStartDate", "paymentStartDate", "chartId", "officePhoneNumber", "notes",
    "weeklyPayday", "payday1", "payday2", "treatmentPrice", "downpaymentAmount", "paydayName1", "paydayName2", "downpaymentDiv",
    "carryOverAmount", "planType"]

  static values = {
    plans: Array,
    patientId: String,
    abc: String
  }

  connect() {
    this.amortized = false;
    $('.plan-div').addClass('d-none');
    this.calculatePlanAmount();
  }

  serializeFormData(form) {
    var data = $(form).serializeArray().reduce(function (output, value) {
      if (output[value.name]) {
        if (Object.prototype.toString.call(output[value.name]) == '[object Array]') {
          var arr = output[value.name];
          arr.push(value.value)
          output[value.name] = arr;
        } else {
          var str = output[value.name];
          var arr = str.split(',');
          arr.push(value.value);
          output[value.name] = arr;
        }
      } else {
        output[value.name] = value.value
      }
      return output;
    }, {})

    return data;
  }

  calculatePlan() {
    var patientId = this.patientIdValue;
    var params = this.serializeFormData(this.element);
    $('.loading-spinner').removeClass('d-none');
    $.ajax({
      url: `patients/${patientId}/plans/generate_tentative_schedule`,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        $('.loading-spinner').addClass('d-none');
        return;
      },
      error: (error) => {
        // toastr.error('Something went wrong');
        $('.loading-spinner').addClass('d-none');
        return;
      },
    });
  }

  amortizationPreferenceChanged(e) {
    this.hideExtraDetails();

    this.amortized = (e.target.value === 'true');

    // if (this.amortized) {
    //   // disable installment amount
    //   // enable number of months

    //   this.numberOfMonthsTarget.disabled = this.numberOfMonthsTarget.readonly = false;
    //   this.numberOfMonthsTarget.value = 60;
    // } else {
    //   // enable installment amount
    //   // disable number of months

    //   this.numberOfMonthsTarget.disabled = true;
    //   this.numberOfMonthsTarget.value = 0;
    // }
    this.calculate(null);
  }

  linkedPlanChanged(e) {
    this.hideExtraDetails();

    var planId = e.target.value;
    this.carryOverAmountTarget.value = 0;

    if (planId) {
      var plan = this.plansValue.find(plan => plan.id == planId);
      this.linkedPlan = plan;
      this.carryOverAmountTarget.value = plan.custom_attributes.balance;
      this.treatmentPriceTarget.value = plan.treatment_price;
      this.downpaymentAmountTarget.value = plan.downpayment_amount;
      var treatmentStartDate = new Date(plan.treatment_start_date);
      var paymentStartDate = new Date(plan.custom_attributes.upcoming_payment_date);
      this.broadcastPaymentStartDate(paymentStartDate);
      this.broadcastTreatmentStartDate(treatmentStartDate);
      this.paydayCycleTarget.value = plan.payday_cycle;
      this.planTypeTarget.value = plan.plan_type;
      this.paydayName1Target.querySelector('.form-control').value = plan.pay_day_name_1;
      this.paydayName2Target.querySelector('.form-control').value = plan.pay_day_name_2;
      this.payday1Target.querySelector('.form-control').value = plan.payday_1;
      this.payday2Target.querySelector('.form-control').value = plan.payday_2;
      if (this.hasWeeklyPaydayTarget) {
        this.weeklyPaydayTarget.querySelector('.form-control').value = plan.weekly_payday;
      }
    }

    this.calculatePlanAmount();
  }

  calculatePlanAmount() {
    this.hideExtraDetails();
    var planType = this.planTypeTarget.value;
    const treatmentPrice = parseFloat(this.treatmentPriceTarget.value);
    const downpaymentAmount = parseFloat(this.downpaymentAmountTarget.value);

    if (planType == 'downpayment_plan') {
      const planAmount = (downpaymentAmount) + parseFloat(this.carryOverAmountTarget.value);
      this.planAmountTarget.value = planAmount.toFixed(2);
    } else {
      const planAmount = (treatmentPrice - downpaymentAmount) + parseFloat(this.carryOverAmountTarget.value);
      this.planAmountTarget.value = planAmount.toFixed(2);
    }
    this.calculate(null);
  }

  planTypeChanged(e) {
    this.hideExtraDetails();
    var planType = e.target.value;
    var input = (this.downpaymentDivTarget).querySelector('input');
    var treatmentStartDateInput = $('.treatmentStartDate')[0].querySelector('input');
    input.value = 0;

    if (planType == 'downpayment_plan') {
      $('.treatmentStartDate')[0].classList.add('d-none');
      // this.downpaymentDivTarget.classList.add('d-none');
      // input.required = false;
      treatmentStartDateInput.required = false
    } else {
      $('.treatmentStartDate')[0].classList.remove('d-none');
      // this.downpaymentDivTarget.classList.remove('d-none');
      treatmentStartDateInput.required = true;
      // input.required = true;
    }
    this.calculatePlanAmount();
  }

  broadcastPaymentStartDate(paymentStartDate) {
    var vm = this;
    var nodes = vm.element.querySelectorAll('.paymentStartDate');
    nodes.forEach(function (node) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'date-picker');
      destCtrl.setValue(paymentStartDate);
    })
  }

  broadcastTreatmentStartDate(treatmentStartDate) {
    var vm = this;
    var nodes = vm.element.querySelectorAll('.treatmentStartDate');
    nodes.forEach(function (node) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'date-picker');
      destCtrl.setValue(treatmentStartDate);
    })
  }

  handleTreatmentStartDate() {
    const treatmentStartDateContainer = $('.treatmentStartDate')[0];
    const treatmentStartDateInput = treatmentStartDateContainer.querySelector('input');
    const isDownpaymentPlan = this.planTypeTarget.value === 'downpayment_plan';

    treatmentStartDateContainer.classList.toggle('d-none', isDownpaymentPlan);
    treatmentStartDateInput.required = !isDownpaymentPlan;
  }

  calculate(e) {
    // Hide div initially
    $('.plan-div').addClass('d-none');

    // Validate inputs
    if (e && !this.validateInputs()) {
      alert("Please fill in all the fields");
      return;
    }
    // Parse values from form inputs
    const serviceFees = parseFloat(this.serviceFeesTarget.value);
    const planAmount = parseFloat(this.planAmountTarget.value);
    const paydayAmount = parseFloat(this.paydayAmountTarget.value);

    // Calculate service fee amount and total plan amount
    const serviceFeeAmount = (serviceFees / 100) * planAmount;
    let totalPlanAmount = paydayAmount * (planAmount + serviceFeeAmount) / paydayAmount;

    // Populate the numberOfMonths and totalPlanAmount fields
    this.totalPlanAmountTarget.value = totalPlanAmount.toFixed(2);


    // Run any additional logic to toggle field visibility
    this.calculateMonthsAndInstallmentsCount(e);
    this.handleTreatmentStartDate();

    // Show div
    if (e != null) {
      this.calculatePlan();
      $('.plan-div').removeClass('d-none');
    }
  }

  hideExtraDetails(e) {
    $('.plan-div').addClass('d-none');
  }

  calculateMonthsAndInstallmentsCount(e) {
    if (e) {
      this.hideExtraDetails();
    }

    const planAmount = parseFloat(this.planAmountTarget.value);
    const paydayCycle = this.paydayCycleTarget.value;
    const serviceFees = parseFloat(this.serviceFeesTarget.value);
    const serviceFeeAmount = (serviceFees / 100) * planAmount;
    const paydayAmount = parseFloat(this.paydayAmountTarget.value);

    // Calculate number of months or installments based on paydayCycle
    let numberOfMonths, numberOfInstallments;

    if (paydayCycle === "monthly") {
      numberOfMonths = Math.ceil((planAmount + serviceFeeAmount) / paydayAmount);
      numberOfInstallments = numberOfMonths;
    } else if (paydayCycle === "twice_a_month" || paydayCycle === "twice_a_month_by_pay_cycle") {
      numberOfMonths = (((planAmount + serviceFeeAmount) / paydayAmount) / 2);
      numberOfInstallments = Math.ceil(numberOfMonths * 2)  // or any logic to calculate the number of twice-a-month installments
    } else if (paydayCycle === "weekly") {
      numberOfMonths = (((planAmount + serviceFeeAmount) / paydayAmount) / 4.3);
      let years = numberOfMonths / 12;
      numberOfInstallments = Math.ceil(years * 52); // 4 installments per month
    } else if (paydayCycle === "bi_weekly") {
      numberOfMonths = Math.ceil(2 * 4 * (planAmount + serviceFeeAmount) / paydayAmount);
      let years = numberOfMonths / 12;
      numberOfInstallments = (years * 52) / 2; // 8 installments per month
    }

    this.numberOfInstallmentsTarget.value = Math.ceil(numberOfInstallments);
    this.numberOfMonthsTarget.value = numberOfMonths.toFixed(2);
    this.toggleFieldVisibility();
  }


  validateInputs() {
    const calculatorDiv = document.querySelector('.calculator-div');
    let allValid = true;

    calculatorDiv.querySelectorAll('input:not([readonly]), select').forEach((input) => {
      if (!input.value && input.required) {
        allValid = false;
      }
    });

    [this.planAmountTarget, $('#plan_payment_start_date')[0]].forEach((input) => {
      if (!input.value || input.value == 0) {
        allValid = false;
      }
    });

    return allValid;
  }

  toggleFieldVisibility() {
    const paydayCycle = this.paydayCycleTarget.value;

    if (this.hasWeeklyPaydayTarget){
      var weeklyPaydayGroup = this.weeklyPaydayTarget.closest('.form-group');
    }
    else {
      var weeklyPaydayGroup = null;
    }
    const payday1Group = this.payday1Target.closest('.form-group');
    const payday2Group = this.payday2Target.closest('.form-group');
    const paydayName1Group = this.paydayName1Target.closest('.form-group');
    const paydayName2Group = this.paydayName2Target.closest('.form-group');

    payday1Group.classList.add("d-none");
    payday2Group.classList.add("d-none");
    paydayName1Group.classList.add("d-none");
    paydayName2Group.classList.add("d-none");
    if (weeklyPaydayGroup) {
      weeklyPaydayGroup.classList.add("d-none");
    }

    if (weeklyPaydayGroup){
      (this.weeklyPaydayTarget).querySelector('select').required = false;
    }
    (this.payday1Target).querySelector('select').required = false;
    (this.payday2Target).querySelector('select').required = false;
    (this.paydayName1Target).querySelector('select').required = false;
    (this.paydayName2Target).querySelector('select').required = false;

    if (paydayCycle === "monthly") {

    } else if (paydayCycle === "twice_a_month") {
      payday1Group.classList.remove("d-none");
      payday2Group.classList.remove("d-none");
      (this.payday1Target).querySelector('select').required = true;
      (this.payday2Target).querySelector('select').required = true;

    } else if (paydayCycle === "twice_a_month_by_pay_cycle") {
      paydayName1Group.classList.remove("d-none");
      paydayName2Group.classList.remove("d-none");
      (this.paydayName1Target).querySelector('select').required = true;
      (this.paydayName2Target).querySelector('select').required = true;

    } else if (paydayCycle === "weekly" || paydayCycle === "bi_weekly") {
      weeklyPaydayGroup.classList.remove("d-none");
      (this.weeklyPaydayTarget).querySelector('select').required = true;

    } else {
      if (weeklyPaydayGroup)
        weeklyPaydayGroup.classList.remove("d-none");
      payday1Group.classList.remove("d-none");
      payday2Group.classList.remove("d-none");
    }
  }
}

